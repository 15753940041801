import { Controller } from "@hotwired/stimulus";

import tinymce from "tinymce";
import Rails from "@rails/ujs";

const rootUrl = document.currentScript.src.replace(/\/packs.*$/, "");

export default class extends Controller {
  connect() {
    tinymce.init({
      selector: `#${this.element.id}`,
      height: 500,
      base_url: rootUrl + "/packs/js/tinymce",
      menubar: "insert",
      resize: "both",
      automatic_uploads: true,
      file_picker_types: "image",
      image_title: true,
      image_caption: true,
      image_dimensions: false,
      image_class_list: [
        {title: 'Full width', value: 'img-fluid'}
      ],
      images_upload_url: this.data.get("images-upload-url"),
      images_upload_handler: this.handleImageUpload.bind(this),
      content_css: [this.data.get("custom-css-url")],
      relative_urls : false,
      remove_script_host : false,
      convert_urls : true,
      plugins: [
        "advlist autolink lists link image code charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | fullscreen image |" +
        " bold italic backcolor | alignleft aligncenter " +
        " alignright alignjustify | bullist numlist outdent indent | " +
        " removeformat | help",
      media_dimensions: false,
      setup: (editor) => {
        editor.on('BeforeSetContent', (e) => {
          if(e.content.startsWith('<iframe src=')) {
            let videoContent = $(e.content).removeAttr('width').removeAttr('height');
            videoContent.addClass('video-container__video');
            videoContent.attr({
                                title: "YouTube video player",
                                frameborder: "0",
                                allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                              });
            e.content = `<p class="video-container__content text-center">${videoContent[0].outerHTML}</p>`;
          }
        });
      }
    });
  }

  handleImageUpload(blobInfo, success, failure, progress) {
    let formData = new FormData();
    formData.append("asset[file]", blobInfo.blob(), blobInfo.filename());

    Rails.ajax({
      url: this.data.get("images-upload-url"),
      type: "POST",
      accept: "json",
      error: ({message}) => {
        failure(message)
      },
      data: formData,
      success: ({ location }) => {
        success(location);
      },
      beforeSend: (xhr) => {
        xhr.upload.onprogress = (e) => {
          progress((e.loaded / e.total) * 100);
        };
        return true;
      },
    });
  }
}
