import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "burgerMenu"]

  toggleMenu(e) {
    this.menuTarget.classList.toggle('header__menu-body--active');
    this.burgerMenuTarget.classList.toggle('burger-menu--active');
    document.querySelector('.header__ellipse').classList.toggle('header__ellipse--hide');
    document.body.classList.toggle('overflow-hidden');
  }
}
