require("@rails/ujs").start();
// import "@hotwired/turbo-rails"
require("@rails/activestorage").start();
require("channels");
require("@nathanvda/cocoon");

import './react';
import $ from "jquery";
import toastr from 'toastr';

toastr.options = ({
  closeButton: true,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 2000
});

global.toastr = toastr;

import "controllers"
